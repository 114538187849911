var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('form',{class:_vm.b(),on:{"submit":function($event){$event.preventDefault();return _vm.saveProfile.apply(null, arguments)}}},[_c('FormGroup',[_c('Field',{attrs:{"placeholder":"ФИО","label":"ФИО","disabled":true},model:{value:(_vm.userModel.name),callback:function ($$v) {_vm.$set(_vm.userModel, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"userModel.name"}})],1),_c('FormGroup',[_c('Field',{attrs:{"placeholder":"Email","label":"Email","disabled":true},model:{value:(_vm.userModel.email),callback:function ($$v) {_vm.$set(_vm.userModel, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"userModel.email"}})],1),_c('FormGroup',[_c('Field',{directives:[{name:"mask",rawName:"v-mask",value:('+7 ### ### ## ##'),expression:"'+7 ### ### ## ##'"}],attrs:{"placeholder":"Телефон","label":"Телефон","disabled":true},model:{value:(_vm.userModel.phone),callback:function ($$v) {_vm.$set(_vm.userModel, "phone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"userModel.phone"}})],1),_c('FormGroup',[_c('Field',{attrs:{"placeholder":"Юридическая форма и название","label":"Юридическая форма и название","error-text":_vm.$v.userModel.requisites.name_full.$dirty &&
        !_vm.$v.userModel.requisites.name_full.required
          ? 'Поле не заполнено'
          : null,"has-error":_vm.$v.userModel.requisites.name_full.$error,"is-shake-error":true},on:{"blur":function($event){return _vm.$v.userModel.requisites.name_full.$touch()}},model:{value:(_vm.userModel.requisites.name_full),callback:function ($$v) {_vm.$set(_vm.userModel.requisites, "name_full", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"userModel.requisites.name_full"}})],1),_c('FormGroup',[_c('Field',{attrs:{"placeholder":"Имя и инициалы представителя","label":"Имя и инициалы представителя","error-text":_vm.$v.userModel.requisites.ceo_imp.$dirty &&
        !_vm.$v.userModel.requisites.ceo_imp.required
          ? 'Поле не заполнено'
          : null,"has-error":_vm.$v.userModel.requisites.ceo_imp.$error,"is-shake-error":true},on:{"blur":function($event){return _vm.$v.userModel.requisites.ceo_imp.$touch()}},model:{value:(_vm.userModel.requisites.ceo_imp),callback:function ($$v) {_vm.$set(_vm.userModel.requisites, "ceo_imp", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"userModel.requisites.ceo_imp"}})],1),_c('FormGroup',[_c('Field',{attrs:{"placeholder":"ИНН","label":"ИНН","error-text":_vm.$v.userModel.requisites.inn.$dirty &&
        !_vm.$v.userModel.requisites.inn.required
          ? 'Поле не заполнено'
          : null,"has-error":_vm.$v.userModel.requisites.inn.$error,"is-shake-error":true},on:{"blur":function($event){return _vm.$v.userModel.requisites.inn.$touch()}},model:{value:(_vm.userModel.requisites.inn),callback:function ($$v) {_vm.$set(_vm.userModel.requisites, "inn", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"userModel.requisites.inn"}})],1),_c('FormGroup',[_c('Field',{attrs:{"placeholder":"КПП","label":"КПП","has-error":_vm.$v.userModel.requisites.kpp.$error,"is-shake-error":true},on:{"blur":function($event){return _vm.$v.userModel.requisites.kpp.$touch()}},model:{value:(_vm.userModel.requisites.kpp),callback:function ($$v) {_vm.$set(_vm.userModel.requisites, "kpp", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"userModel.requisites.kpp"}})],1),_c('FormGroup',[_c('Field',{attrs:{"placeholder":"Расчетный счет","label":"Расчетный счет","error-text":_vm.rsValidationText,"has-error":_vm.$v.userModel.requisites.rs.$error,"is-shake-error":true},on:{"blur":function($event){return _vm.$v.userModel.requisites.rs.$touch()}},model:{value:(_vm.userModel.requisites.rs),callback:function ($$v) {_vm.$set(_vm.userModel.requisites, "rs", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"userModel.requisites.rs"}})],1),_c('FormGroup',[_c('Field',{attrs:{"placeholder":"БИК","label":"БИК","error-text":_vm.$v.userModel.requisites.bik.$dirty &&
        !_vm.$v.userModel.requisites.bik.required
          ? 'Поле не заполнено'
          : null,"has-error":_vm.$v.userModel.requisites.bik.$error,"is-shake-error":true},on:{"blur":function($event){return _vm.$v.userModel.requisites.bik.$touch()}},model:{value:(_vm.userModel.requisites.bik),callback:function ($$v) {_vm.$set(_vm.userModel.requisites, "bik", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"userModel.requisites.bik"}})],1),_c('div',{class:_vm.b('btns')},[_c('BaseButton',{attrs:{"color":'gray'},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Отмена")]),_c('BaseButton',{attrs:{"type":"submit"}},[_vm._v("Сохранить")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }